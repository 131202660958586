body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.margintp {
  margin-top: 90px;
}

.marginbm {
  margin-bottom: 90px;
}

.gallery-img{
  display: inline;
}

.sende-button {
  color:whitesmoke;
}
.sende-button:hover {
  color: black;
}

.lang-dropdown{
  margin: 15px;
  width: 50px;
  background-color: white;
  border-radius: 10px;
  border-style: none;
}

/* div{
  border: 1px solid red;
} */

.werkzeug-image{
  max-width:100%; 
  max-height:100%;
  width: 100%;
}

.werkzeug-text{
  display:flex;
  align-items:center;
  flex-direction: column;
  padding: 30px;
}

@media only screen and (min-width: 768px) {
  .order-md-6{
    order: 6;
  }
}

.nav-link {
  font-family: 'Lato',sans-serif;
  text-transform: uppercase;
  font-size: 15;
  font-weight: 400;
}

/* .navbar-default .navbar-nav > .active > a:after, .navbar-default .navbar-nav > .active > a:hover:after, .navbar-default .navbar-nav > .active > a:focus:after  */
a.nav-link::after{
  display: block;
  width: 0px;
  height: 3px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, #582922 0%, #7d4840 100%) !important;
  content: "" !important;
  transition: width 0.2s !important; 
}

.navbar-nav a.nav-link:hover::after{
  display: block;
  width: 40px;
  height: 3px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, #582922 0%, #7d4840 100%) !important;
  content: "" !important;
  transition: width 0.2s !important; 
}
/* 
a.nav-link{
  border: 1px solid red;
} */

/* a.nav-link:hover::after{
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #582922 0%, #7d4840 100%) !important;
  content: "" !important;
  transition: width 0.2s !important; 
} */

.brown-marker{
  margin-right: 5px;
}

.close {
  position: absolute;
  right: 9px;
  top: 9px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}