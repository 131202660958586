.card-image {
    object-fit: contain;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
}

/* .card-image:hover {
    object-fit: scale-down; 
} 
*/

.custom-card {
    box-shadow: 0 14px 26px rgba(0,0,0,0.04) !important;
    transition: all 0.3s ease-out;
}


.custom-card:hover {
    transform:  scale(1.03) translateZ(0);
    /* box-shadow: 0 24px 36px rgba(0,0,0,0.11), 0 24px 46px var(--box-shadow-color) imp !important; */
  }

.card-album {
    margin-bottom: 40px;
}

.filter-btn {
    border-radius: 30px;
    margin: 3px;
}

.cursorp:hover{
    cursor: pointer; 
}

/* .h-35{
    height: 31% !important;
} */